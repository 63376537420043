import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"

const HeroContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    height: 30rem;
    background-size: cover;
    background-position: center;
`

const HeroContent = styled.div`
    background-image: linear-gradient(90deg, rgb(55, 124, 158, 0.5) 0%, rgb(216, 137, 218, 0.5) 76.5%);
    width: 100%;
    height: 100%;
`

const Hero = ({ children, bgimage }) => {
    return (
        <HeroContainer
            css={css`
                background-image: url(${bgimage});
            `}
        >
            <HeroContent>{children}</HeroContent>
        </HeroContainer>
    )
}

export default Hero
