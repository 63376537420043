import React from "react"
import { graphql } from "gatsby"
import { getLessonContentItemData, getVideoContentItemData, SEO_DESCRIPTION, SEO_KEYWORDS } from "../utils"
import Container from "../components/layout/Container"
import Header from "../components/Header"
import Hero from "../components/Hero"
import heroImg from "../assets/img/hero.jpg"
import TopHeroContent from "../components/TopHeroContent"
import ContentListItem from "../components/ContentListItem"
import Content from "../components/layout/Content"
import Space from "../components/Space"
import { Helmet } from "react-helmet"

export default props => {
    const lessons = props.data.allMongodbVpletaysyaLessons.edges.map(item => {
        return getLessonContentItemData(item)
    })
    const videos = props.data.allMongodbVpletaysyaVideos.edges.map(item => {
        return getVideoContentItemData(item)
    })

    const items = [...lessons, ...videos]

    const sortedItems = items.sort((item1, item2) => {
        let d1, d2

        switch (item1.type) {
            default:
                d1 = new Date()
        }

        switch (item2.type) {
            default:
                d2 = new Date()
        }

        return d1.getTime() < d2.getTime()
            ? 1
            : d1.getTime() > d2.getTime()
            ? -1
            : 0
    })

    return (
        <Container>
            <Helmet>
                <title>Плетение косичек. Учимся плести косы вместе</title>
                <meta name="keywords" content={SEO_KEYWORDS} />
                <meta name="description" content={SEO_DESCRIPTION} />
            </Helmet>
            <Header />
            <Hero bgimage={heroImg}>
                <TopHeroContent />
            </Hero>
            <Space size={6} />
            <Content>
                {sortedItems.map(item => (
                    <ContentListItem
                        url={`${item.url}?back=h`}
                        title={item.title}
                        text={item.text}
                        key={item.id}
                        imageUrl={item.imageUrl}
                    />
                ))}
            </Content>
            <Space />
        </Container>
    )
}

export const IndexQuery = graphql`
    query lessonsListQueryAndLessonsListQuery {
        allMongodbVpletaysyaLessons(
            sort: { fields: [date], order: DESC }
            limit: 10
        ) {
            edges {
                node {
                    id
                    date
                    old_id
                    old_url
                    title
                    text
                    url
                    published
                }
            }
        }
        allMongodbVpletaysyaVideos(
            sort: { fields: [date], order: DESC }
            limit: 10
        ) {
            edges {
                node {
                    id
                    date
                    description
                    title
                    text
                    old_id
                    old_link
                    player
                }
            }
        }
    }
`
