import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const ContentListItem = styled.div`
    margin-bottom: 4rem;
`
const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

const Title = styled.div`
    margin-bottom: 1rem;
    color: black;
    font-family: "Yanone Kaffeesatz";
    font-size: 2rem;
    font-weight: 500;
    text-decoration: none;
    &:hover {
        color: #377c9e;
    }
`

const Text = styled.div`
    color: black;
    font-weight: 300;
    line-height: 1.6em;
    width: 100%;
`
const Image = styled.div`
    ${"" /* width: 40%; */}
    height: 225px;
    background-size: cover;
    background-position: center;
    margin-right: 2rem;
    transition: 0.3s ease-in-out;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    border-radius: 5px;
    &:hover {
        transform: scale(1.02);
    }
    @media (max-width: 768px) {
        margin-right: 0;
    }
`

export default ({
    title = "",
    text = "",
    url = "",
    imageUrl = "",
    onClick,
}) => {
    return (
        <ContentListItem>
            <Link
                onClick={onClick}
                to={url}
                css={css`
                    text-decoration: none;
                `}
            >
                <Title>{title}</Title>
            </Link>
            <Content>
                {imageUrl && (
                    <Link
                        onClick={onClick}
                        to={url}
                        css={css`
                            width: 40%;
                            @media (max-width: 768px) {
                                margin-bottom: 1rem;
                                width: 100%;
                            }
                        `}
                    >
                        <Image
                            css={css`
                                background-image: url(${imageUrl});
                            `}
                        />
                    </Link>
                )}
                <Text>{text}</Text>
            </Content>
        </ContentListItem>
    )
}
