import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Space from "./Space"
import { Link } from "gatsby"
import insaImg from "../assets/img/insta.jpg"
import youtubeImg from "../assets/img/youtube.jpg"
import trainingImg from "../assets/img/trainings/tr1min.jpg"

const TopHeroContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
`

const MainTitle = styled.h1`
    color: white;
    margin-top: 2.5rem;
    text-align: center;
`

const Resources = styled.div`
    position: absolute;
    top: 190px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`

const ResourceItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 1rem;
    border-radius: 5px;
    height: 17rem;
    width: 20rem;
    background-color: white;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    &:hover {
        transform: scale(1.1);
        div:nth-of-type(2) {
            height: 0;
        }
        div:nth-of-type(1) > div:nth-of-type(2) {
            opacity: 1;
            filter: alpha(opacity=50);
            height: 3rem;
            margin-top: 1rem;
        }
    }
    @media (max-width: 375px - 1) {
        width: 100%;
    }
`
const ResourceItemTitleContainer = styled.div`
    height: 3.4rem;
    background-color: #377c9e;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    background-image: linear-gradient(90deg, #377c9e 0%, #d889da 100%);
`

const ResourceItemTitle = styled.div`
    text-transform: uppercase;
    color: white;
    transition: margin-bottom margin-top 0.5s ease-in;
`
const ResourceItemSubTitle = styled.div`
    color: white;
    opacity: 0;
    height: 0;
    text-align: center;
    transition: opacity 1s ease-in-out, margin-top 0.3s, height 0.8s;
    padding: 0 0.5rem;
`
const ResourceItemBody = styled.div`
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-size: cover;
    position: relative;
    transition: height 0.3s ease-in-out;
`

const hideOnMobile = css`
    @media (max-width: 768px) {
        display: none;
    }
    @media (max-width: 1024px) {
        display: none;
    }
`

const resourcesMobileView = css`
    @media (max-width: 768px) {
        top: 120px;
    }
`

const linkStyle = css`
    text-decoration: none;
`

export default () => {
    return (
        <TopHeroContent>
            <Space />
            <MainTitle>Прически для себя и дочки. Учимся вместе!</MainTitle>
            <Resources css={resourcesMobileView}>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/olyanaryzhnaya/"
                    css={linkStyle}
                    onClick={() => {
                        if (window.ym) {
                            window.ym(516565, "reachGoal", "INSTAGRAM_CLICK")
                        }
                    }}
                >
                    <ResourceItem css={hideOnMobile}>
                        <ResourceItemTitleContainer>
                            <ResourceItemTitle>Instagram</ResourceItemTitle>
                            <ResourceItemSubTitle>
                                Подпишись на Instagram много всего интересного
                            </ResourceItemSubTitle>
                        </ResourceItemTitleContainer>
                        <ResourceItemBody
                            css={css`
                                background-image: url(${insaImg});
                            `}
                        />
                    </ResourceItem>
                </a>
                <Link
                    to="/training"
                    css={linkStyle}
                    onClick={() => {
                        if (window.ym) {
                            window.ym(516565, "reachGoal", "BANNER_MAIN_CLICK")
                        }
                    }}
                >
                    <ResourceItem
                        css={css`
                            height: 20rem;
                            width: 22rem;
                        `}
                    >
                        <ResourceItemTitleContainer>
                            <ResourceItemTitle>
                                Онлайн-курсы от меня
                            </ResourceItemTitle>
                            <ResourceItemSubTitle>
                                Прически для себя. Прически для дочки. Кликай,
                                чтобы узнать больше!
                            </ResourceItemSubTitle>
                        </ResourceItemTitleContainer>
                        <ResourceItemBody
                            css={css`
                                background-image: url(${trainingImg});
                            `}
                        />
                    </ResourceItem>
                </Link>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/4oxygene"
                    css={linkStyle}
                    onClick={() => {
                        if (window.ym) {
                            window.ym(516565, "reachGoal", "YOUTUBE_CLICK")
                        }
                    }}
                >
                    <ResourceItem css={hideOnMobile}>
                        <ResourceItemTitleContainer>
                            <ResourceItemTitle>Youtube</ResourceItemTitle>
                            <ResourceItemSubTitle>
                                Подписывайся на канал
                            </ResourceItemSubTitle>
                        </ResourceItemTitleContainer>
                        <ResourceItemBody
                            css={css`
                                background-image: url(${youtubeImg});
                            `}
                        />
                    </ResourceItem>
                </a>
            </Resources>
        </TopHeroContent>
    )
}
